.bg-image {
	background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
	bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.clickable {
	cursor: pointer;
	user-select: none;
}

.bg-glass {
	background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
}

.selected {
	border: 2px solid red;
}